    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
}

.link {
    display: block;
    color: $color-base-800;

    &:hover,
    &:focus {
        color: $color-base-400;
    }

    & + & {
        margin-left: $spacing-3;
    }
}

.icon {
    $size: 1.5rem;

    width: $size;
    height: $size;
}
