    @import "styles/variables";
    @import "styles/mixins";

$story-sticky-bar-height: 53px;

.body {
    @include desktop-up {
        padding-bottom: $story-sticky-bar-height;
    }
}

.container {
    margin-bottom: $spacing-5;
    position: relative;

    @include desktop-up {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: $story-sticky-bar-height;
        margin: 0;
        padding: $spacing-1 $spacing-5;
        background: $color-base-white;
        border-top: 1px solid $color-borders;
        z-index: 5;
    }
}

.innerContainer {
    display: flex;

    @include desktop-up {
        @include container;

        justify-content: space-between;
    }
}
