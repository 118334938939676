    @import "styles/variables";
    @import "styles/mixins";

.title {
    @include heading-1-xxl;

    margin: 0 0 $spacing-4;
    color: $color-base-700;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}
