    @import "styles/variables";
    @import "styles/mixins";

$small-card-image-size: 230px;

.container {
    position: relative;
    box-shadow: 0 6px 12px 0 rgba(#000, 6%);
    transform: scale(1);
    transition: transform 0.05s ease-in-out, box-shadow 0.3s ease-in-out;

    &:not(:last-of-type) {
        margin-bottom: $spacing-7;
    }

    /* stylelint-disable-next-line order/order */
    @include desktop-up {
        &:not(:last-of-type) {
            margin-bottom: 0;
        }
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: 0 16px 24px 0 rgba(#000, 6%);
    }
}

.imageWrapper {
    display: block;
    overflow: hidden;
    text-decoration: none;

    @include desktop-up {
        .image {
            transform: scale(1);
            transition: transform 0.5s ease-in-out;
        }

        &:hover .image {
            transform: scale(1.05);
        }
    }
}

.image,
.placeholder {
    @include border-radius-s;
}

.placeholder > .image {
    border-radius: 0;
}

.content {
    padding: $spacing-3;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-1;
}

.title {
    @include heading-3;
    @include ensure-max-text-height(3, $line-height-m);

    font-weight: $font-weight-bold;
    margin-top: 0;
    margin-bottom: $spacing-2;
}

.titleLink {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
        color: $color-base-500;
    }
}

.subtitle {
    @include text-small;
    @include ensure-max-text-height(2, $line-height-s);

    margin: 0 auto $spacing-2;
    font-weight: $font-weight-regular;
}

.date {
    @include text-small;

    margin: 0;
    color: $color-base-500;
}

.small {
    @include tablet-up {
        display: grid;
        grid-template-columns: $small-card-image-size auto;
        gap: 0 $spacing-4;
        align-items: center;
    }

    .imageWrapper {
        @include small-card-aspect-ratio;
    }

    .content {
        @include tablet-up {
            padding: 0;
        }
    }

    .title {
        @include tablet-up {
            @include heading-3;
            @include ensure-max-text-height(3, $line-height-m);

            margin-bottom: $spacing-1;
        }

        @include desktop-up {
            @include paragraph;
            @include ensure-max-text-height(3, $line-height-s);
        }
    }

    .subtitle {
        @include tablet-up {
            @include paragraph;
            @include ensure-max-text-height(2, $line-height-s);
        }
    }
}

.medium,
.big {
    @include tablet-only {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 0 $spacing-4;

        .content {
            padding: 0;
        }
    }
}

.medium {
    .imageWrapper {
        @include medium-card-aspect-ratio;
    }
}

.big {
    .imageWrapper {
        @include big-card-aspect-ratio;
    }
}

@include tablet-only {
    .medium + .small {
        margin-top: $spacing-9;
    }
}

@include desktop-up {
    .medium {
        grid-column: span 2;
    }

    .big,
    .small {
        grid-column: span 3;
    }
}
