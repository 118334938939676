    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    align-items: center;
    color: $color-base-700;
}

.cta {
    display: none;

    @include desktop-up {
        display: block;
        margin-right: $spacing-3;
    }
}

.button {
    font-size: 0 !important;
    margin-right: $spacing-4;

    @include desktop-up {
        margin-right: 0;

        &:not(:last-child) {
            margin-right: $spacing-3;
        }
    }
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    color: $color-text;

    @include desktop-up {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.button:hover,
.button:focus {
    .icon {
        color: $color-base-500;
    }
}
