    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include border-radius-m;

    margin: $spacing-6 auto;
    padding: $spacing-4;
    background: $color-base-50;
}

.left,
.right {
    flex-shrink: 0;
    width: 2px;
    background-color: $color-base-400;
}

.left {
    margin-right: $spacing-3;
}

.right {
    margin-left: $spacing-3;
}

.content {
    @include paragraph;

    flex: 1;
    text-align: center;
}

.alignLeft {
    text-align: left;
    border-left: 2px solid $color-base-400;
    padding-left: $spacing-3;
}

.alignCenter {
    text-align: center;
    border-left: 2px solid $color-base-400;
    padding-left: $spacing-3;
}

.alignRight {
    text-align: right;
    border-right: 2px solid $color-base-400;
    padding-right: $spacing-3;
}
