    @import "styles/variables";
    @import "styles/mixins";

.link {
    @include text-label;
    @include link-primary;

    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        span {
            text-decoration: underline;
        }
    }

    &:not(:last-child) {
        &::after {
            content: ",";
            margin-right: $spacing-half;
        }
    }
}
