    @import "styles/variables";
    @import "styles/mixins";

.item {
    width: max-content;
    min-width: 100%;
    max-width: 100%;

    & + & {
        border-top: 1px solid $color-borders;
    }

    &.withMobileDisplay {
        @include not-desktop {
            border: 0;
        }
    }
}

.link {
    display: block;
    padding: $spacing-3;
    text-decoration: none;
    color: $color-base-700;
    font-weight: $font-weight-medium;

    &.active {
        color: #56aaae;
    }

    .withMobileDisplay & {
        @include not-desktop {
            @include text-label;

            padding: $spacing-2 $grid-gutter-mobile;
        }
    }
}
