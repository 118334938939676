    @import "styles/variables";
    @import "styles/mixins";

.background {
    @include full-width;

    background: $color-base-50;
    border-bottom: 1px solid $color-borders;
}

.container {
    @include container;

    padding: $spacing-8;
    text-align: center;
}

.title {
    @include heading-1;

    margin-top: 0;
    color: $color-base-700;

    &:only-child {
        margin-bottom: 0;
    }
}

.subtitle {
    @include paragraph;

    max-width: 735px;
    margin: $spacing-3 auto 0;
}
