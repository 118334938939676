@mixin base-card-aspect-ratio {
    /* Corresponds to 540x340px */
    aspect-ratio: 27 / 17;

    @supports not (aspect-ratio: auto) {
        height: 240px;
    }
}

@mixin highlighted-card-aspect-ratio {
    @include base-card-aspect-ratio;

    @supports not (aspect-ratio: auto) {
        height: 240px;
    }
}

@mixin big-card-aspect-ratio {
    @include base-card-aspect-ratio;

    @include tablet-only {
        aspect-ratio: 1 / 1;
    }

    @supports not (aspect-ratio: auto) {
        @include tablet-up {
            height: 320px;
        }

        @include desktop-up {
            height: 340px;
        }
    }
}

@mixin medium-card-aspect-ratio {
    @include base-card-aspect-ratio;

    @include tablet-up {
        aspect-ratio: 1 / 1;
    }

    @supports not (aspect-ratio: auto) {
        @include tablet-up {
            height: 320px;
        }

        @include desktop-up {
            height: 340px;
        }
    }
}

@mixin small-card-aspect-ratio {
    @include base-card-aspect-ratio;

    @include tablet-up {
        aspect-ratio: 1 / 1;
    }

    @supports not (aspect-ratio: auto) {
        @include tablet-up {
            height: 320px;
        }

        @include desktop-up {
            height: 340px;
        }
    }
}
