    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    align-items: center;

    @include desktop-up {
        position: relative;
    }
}

.input,
.message {
    color: $color-text;

    @include desktop-up {
        @include shadow-inset;

        padding: $spacing-1 $spacing-2;
        border: 1px solid $color-borders;
        border-radius: $border-radius-s;
    }
}

.input {
    @include not-desktop {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
}

.paste {
    font-size: 0;
    cursor: pointer;
    appearance: none;
    background: none;
    border: 0;
    padding: 0;

    @include not-desktop {
        color: $color-text;

        &:hover,
        &:focus {
            color: $color-base-500;
        }
    }

    @include desktop-up {
        position: absolute;
        top: 50%;
        right: $spacing-1;
        padding: $spacing-half;
        background: $color-base-100;
        border-radius: $border-radius-s;
        transform: translateY(-50%);

        &:hover,
        &:focus {
            background: $color-base-200;
        }
    }
}

.mobileIcon {
    width: 1.5rem;
    height: 1.5rem;

    @include desktop-up {
        display: none;
    }
}

.desktopIcon {
    width: 1.125rem;
    height: 1.125rem;

    @include not-desktop {
        display: none;
    }
}

.message {
    position: absolute;
    transition: opacity 0.4s ease;
    will-change: opacity;

    @include not-desktop {
        inset: -1px;
        display: flex;
        align-items: center;
        background-color: $color-base-white;
        color: $color-base-500;

        .mobileIcon {
            margin-right: $spacing-2;
            color: $color-base-700;
        }
    }

    @include desktop-up {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-base-white;
        text-align: center;
    }
}

.transitionStart {
    opacity: 0;
}

.transitionFinish {
    opacity: 1;
}
