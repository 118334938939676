    @import "styles/variables";
    @import "styles/mixins";

.button {
    @include border-radius-s;
    @include text-label;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-2 $spacing-3;
    background: none;
    font-weight: $font-weight-regular;
    text-decoration: none;
    appearance: none;
    box-shadow: none;
    cursor: pointer;
    border: 1px solid transparent;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.loading {
        cursor: wait;
    }
}

.primary {
    background-color: var(--prezly-accent-color);
    border-color: var(--prezly-accent-color);
    color: var(--prezly-accent-color-button-text);

    &:not(:disabled) {
        &:hover,
        &:focus {
            background-color: var(--prezly-accent-color-tint);
            border-color: var(--prezly-accent-color-tint);
        }

        &:active {
            background-color: var(--prezly-accent-color-shade);
            border-color: var(--prezly-accent-color-shade);
        }
    }
}

.secondary {
    border-color: var(--prezly-accent-color);
    color: var(--prezly-accent-color);

    &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
            color: var(--prezly-accent-color-tint);
        }

        &:active {
            background-color: $color-base-50;
        }
    }
}

.navigation {
    color: $color-base-700;
    padding: $spacing-1 $spacing-2;

    @include tablet-up {
        &:not(:disabled) {
            /* stylelint-disable-next-line max-nesting-depth */
            &:hover,
            &:focus {
                opacity: 0.8;
            }

            /* stylelint-disable-next-line max-nesting-depth */
            &:active,
            &.active {
                opacity: 1;
                border-color: $color-borders;
            }
        }
    }

    @include mobile-only {
        border-radius: 0;
    }
}

.icon {
    width: 1em;
    height: 1em;

    &.loading {
        margin-right: $spacing-1;
        transform-origin: center;
        animation: spin 1s infinite linear;
    }

    &.left {
        margin-right: $spacing-1;
    }

    &.right {
        margin-left: $spacing-1;
    }
}

.iconOnly {
    .icon {
        margin: 0;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
