    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;

    .active {
        @include desktop-up {
            position: absolute;
            box-sizing: content-box;

            /* stylelint-disable-next-line max-nesting-depth */
            .menu::after {
                content: "";
                position: absolute;
                right: $spacing-3;
                top: -10px;
                overflow: hidden;
                width: 0;
                height: 0;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
                border-bottom: 10px solid $color-base-white;
            }
        }
    }
}

@mixin absolute-menu-position {
    position: absolute;
    left: 0;
    transform-origin: top left;
    width: 328px;
    margin: calc(#{$spacing-5} + 4px) 0 0;
    padding: 0;
    background: $color-base-white;
}

.buttonWithMobileDisplay {
    @include not-desktop {
        width: 100%;
    }
}

.menu {
    list-style: none;

    &.withMobileDisplay {
        @include not-desktop {
            padding: 0;
            padding-bottom: $spacing-4;
            margin: 0;
        }

        @include desktop-up {
            @include absolute-menu-position;
        }
    }

    &:not(.withMobileDisplay) {
        @include absolute-menu-position;
    }
}

.container:last-child {
    .menu {
        right: auto;
        transform-origin: top right;
    }
}

.caret {
    margin-left: $spacing-2;
    width: $spacing-2;
    height: $spacing-2;
}

.caretOpen {
    transform: rotate(180deg);
}

.transition {
    transition: opacity 0.2s ease, transform 0.2s ease;

    &.withMobileDisplay {
        @include not-desktop {
            transition: opacity 0.2s ease, max-height 0.2s ease;
        }
    }
}

.transitionOpenStart {
    opacity: 0;
    transform: scale(0.9);

    &.withMobileDisplay {
        @include not-desktop {
            transform: none;
            max-height: 0;
        }
    }
}

.transitionOpenFinish {
    opacity: 1;
    transform: scale(1);

    &.withMobileDisplay {
        @include not-desktop {
            transform: none;
            max-height: 500px;
        }
    }
}

.flag {
    margin-left: $spacing-2;
}

.closeWrapper {
    padding: $spacing-3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
